import React from "react"
import {
  Container,
  Layout,
  SEO,
  Grid,
  TextRow,
  ImageRow,
  ExternalLink,
  Tag,
} from "../../components"

import example from "../../images/projects/tangram.png"

const TangramDesignSystem = () => (
  <Layout hideProfile>
    <SEO title="Project: Tangram Design System - RD Station" />
    <Container>
      <Grid>
        <TextRow>
          <h1>Tangram Design System - RD Station</h1>
          <Tag>React</Tag>
          <Tag>Styled Components</Tag>
          <Tag>NodeJS</Tag>
          <Tag>Storybook</Tag>
          <Tag>Lerna</Tag>
          <Tag>Jest</Tag>
          <Tag>React Testing Library</Tag>
          <Tag>Webpack</Tag>
          <Tag>Rollup</Tag>
          <Tag>BabelJS</Tag>

          <p>
            The{" "}
            <a
              href="https://tangram.rdstation.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tangram Design System
            </a>{" "}
            is a set of fundamental principles, interface standards and detailed
            components, from concepts to their applications.
          </p>
          <p>
            The main purpose of this system is to give more autonomy so that
            teams can quickly create consistent and high-quality experiences.
          </p>

          <p>
            As a Head of development in the Design System I was responsible for:
            <ul>
              <li>Creating all initial structure;</li>
              <li>Code standards and handbooks for collaboration;</li>
              <li>Creating methodologies of releases and publishes;</li>
              <li>Coding complex components;</li>
              <li>Building tools for developers;</li>
              <li>Identifying the next steps for the project;</li>
              <li>
                Ensuring the quality of the lib educating contributors with good
                practices adopted for all team;
              </li>
              <li>Measuring the complexity of our deliverables;</li>
              <li>Reporting updates on our activities for all company;</li>
            </ul>
          </p>

          <p>
            Click on the link below to see the result.
            <ExternalLink
              href="https://tangram.rdstation.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tangram Design System
            </ExternalLink>
          </p>
        </TextRow>

        <ImageRow>
          <img alt="Tangram design system example" src={example} />
        </ImageRow>
      </Grid>
    </Container>
  </Layout>
)

export default TangramDesignSystem
